import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faArrowsAltV, faUsers, faPercentage, faClock, faDoorOpen } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [numberOfFloors, setNumberOfFloors] = useState(3);
  const [floorHeight, setFloorHeight] = useState(3.5);
  const [elevatorSpeed, setElevatorSpeed] = useState(1.5);
  const [elevatorCapacity, setElevatorCapacity] = useState(16);
  const [buildingUsers, setBuildingUsers] = useState(50);
  const [peakUsersPercentage, setPeakUsersPercentage] = useState(5);
  const [peakPeriod, setPeakPeriod] = useState(60);
  const [stopTime, setStopTime] = useState(5);
  const [doorTime, setDoorTime] = useState(3);
  const [elevatorDemand, setElevatorDemand] = useState(0);
  const [averageWaitingTime, setAverageWaitingTime] = useState(0);
  const [language, setLanguage] = useState('en'); // English by default

  // Validation Function
  const validateInput = () => {
    return (
      numberOfFloors > 0 &&
      floorHeight > 0 &&
      elevatorSpeed > 0 &&
      elevatorCapacity > 0 &&
      buildingUsers > 0 &&
      peakUsersPercentage >= 0 &&
      peakUsersPercentage <= 100 &&
      peakPeriod > 0 &&
      stopTime >= 0 &&
      doorTime >= 0
    );
  };

  // Calculate elevator demand and average waiting time
  const calculateElevatorDemand = () => {
    if (!validateInput()) {
      setElevatorDemand(language === 'en' ? "Invalid input" : "إدخال غير صالح");
      setAverageWaitingTime(0);
      return;
    }

    const Nf = parseInt(numberOfFloors);
    const H = parseFloat(floorHeight);
    const V = parseFloat(elevatorSpeed);
    const P = parseInt(elevatorCapacity);
    const users = parseInt(buildingUsers);
    const peakPercentage = parseFloat(peakUsersPercentage) / 100;
    const T = parseInt(peakPeriod);
    const t_s = parseFloat(stopTime);
    const t_d = parseFloat(doorTime);

    const H_total = H * Nf;
    const S = Nf - 1;
    const D = Nf;
    const RTT = (2 * H_total / V) + (t_s * S) + (t_d * D);
    const peakUsers = users * peakPercentage;
    const HC = (T / RTT) * P;
    const N = Math.ceil(peakUsers / HC);
    const AWT = RTT / (2 * N);

    setElevatorDemand(N);
    setAverageWaitingTime(AWT);
  };

  // Call calculateElevatorDemand whenever inputs change
  useEffect(() => {
    calculateElevatorDemand();
  }, [numberOfFloors, floorHeight, elevatorSpeed, elevatorCapacity, buildingUsers, peakUsersPercentage, peakPeriod, stopTime, doorTime, calculateElevatorDemand]); // Added calculateElevatorDemand to dependencies

  // Reset form fields
  const resetForm = () => {
    setNumberOfFloors(3);
    setFloorHeight(3.5);
    setElevatorSpeed(1.5);
    setElevatorCapacity(16);
    setBuildingUsers(50);
    setPeakUsersPercentage(5);
    setPeakPeriod(60);
    setStopTime(5);
    setDoorTime(3);
    setElevatorDemand(0);
    setAverageWaitingTime(0);
  };

  // Toggle language between 'en' and 'ar'
  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'ar' : 'en');
  };

  // Language Strings
  const strings = {
    en: {
      title: 'Elevator Demand Calculator',
      floors: 'Number of Floors',
      floorHeight: 'Floor Height (m)',
      speed: 'Elevator Speed (m/s)',
      capacity: 'Elevator Capacity (people)',
      users: 'Number of Building Users',
      peakPercentage: 'Peak Users Percentage (%)',
      peakPeriod: 'Peak Period (seconds)',
      stopTime: 'Stop Time per Floor (seconds)',
      doorTime: 'Door Time (seconds)',
      elevatorDemand: 'Elevator Demand',
      averageWaitingTime: 'Average Waiting Time',
      reset: 'Reset',
    },
    ar: {
      title: 'حاسبة الطلب على المصاعد',
      floors: 'عدد الطوابق',
      floorHeight: 'ارتفاع الطابق (م)',
      speed: 'سرعة المصعد (م/ث)',
      capacity: 'سعة المصعد (أشخاص)',
      users: 'عدد مستخدمي المبنى',
      peakPercentage: 'نسبة المستخدمين في الذروة (%)',
      peakPeriod: 'فترة الذروة (ثواني)',
      stopTime: 'زمن التوقف لكل طابق (ثواني)',
      doorTime: 'زمن فتح وغلق الأبواب (ثواني)',
      elevatorDemand: 'الطلب على المصاعد',
      averageWaitingTime: 'متوسط زمن الانتظار',
      reset: 'إعادة تعيين',
    },
  };

  return (
    <div className={`App ${language === 'ar' ? 'rtl' : ''}`}>
      <header className="App-header">
        {/* Language Switcher */}
        <button className="language-switch" onClick={toggleLanguage}>
          {language === 'en' ? 'ع' : 'E'}
        </button>

        <h1>{strings[language].title}</h1>

        {/* Input Fields */}
        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faBuilding} /></span>
            <span className="label-text">{strings[language].floors}:</span>
          </label>
          <input type="number" value={numberOfFloors} onChange={e => setNumberOfFloors(e.target.value)} />
        </div>

        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faArrowsAltV} /></span>
            <span className="label-text">{strings[language].floorHeight}:</span>
          </label>
          <input type="number" value={floorHeight} onChange={e => setFloorHeight(e.target.value)} />
        </div>

        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faTachometerAlt} /></span>
            <span className="label-text">{strings[language].speed}:</span>
          </label>
          <select value={elevatorSpeed} onChange={e => setElevatorSpeed(e.target.value)}>
            {[1.0, 1.5, 2.0, 2.5].map(speed => (
              <option key={speed} value={speed}>{speed.toFixed(1)} m/s</option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faUsers} /></span>
            <span className="label-text">{strings[language].capacity}:</span>
          </label>
          <select value={elevatorCapacity} onChange={e => setElevatorCapacity(e.target.value)}>
            <option value={4}>4 persons (320 kg)</option>
            <option value={5}>5 persons (400 kg)</option>
            <option value={6}>6 persons (450 kg)</option>
            <option value={8}>8 persons (630 kg)</option>
            <option value={10}>10 persons (800 kg)</option>
            <option value={12}>12 persons (900 kg)</option>
            <option value={13}>13 persons (1000 kg)</option>
            <option value={15}>15 persons (1150 kg)</option>
            <option value={16}>16 persons (1200 kg)</option>
            <option value={17}>17 persons (1275 kg)</option>
            <option value={18}>18 persons (1350 kg)</option>
            <option value={21}>21 persons (1600 kg)</option>
          </select>
        </div>

        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faUsers} /></span>
            <span className="label-text">{strings[language].users}:</span>
          </label>
          <input type="number" value={buildingUsers} onChange={e => setBuildingUsers(e.target.value)} />
        </div>

        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faPercentage} /></span>
            <span className="label-text">{strings[language].peakPercentage}:</span>
          </label>
          <input type="number" value={peakUsersPercentage} onChange={e => setPeakUsersPercentage(e.target.value)} />
        </div>

        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faClock} /></span>
            <span className="label-text">{strings[language].peakPeriod}:</span>
          </label>
          <input type="number" value={peakPeriod} onChange={e => setPeakPeriod(e.target.value)} />
        </div>

        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faClock} /></span>
            <span className="label-text">{strings[language].stopTime}:</span>
          </label>
          <input type="number" value={stopTime} onChange={e => setStopTime(e.target.value)} />
        </div>

        <div className="input-group">
          <label>
            <span className="icon"><FontAwesomeIcon icon={faDoorOpen} /></span>
            <span className="label-text">{strings[language].doorTime}:</span>
          </label>
          <input type="number" value={doorTime} onChange={e => setDoorTime(e.target.value)} />
        </div>

        {/* Reset Button */}
        <button onClick={resetForm} className="reset-button">{strings[language].reset}</button>

        {/* Output */}
        <div className="output">
          <h2 style={{ color: '#FFD700' }}>{strings[language].elevatorDemand}: {elevatorDemand}</h2>
          <h2 style={{ color: '#FFD700' }}>{strings[language].averageWaitingTime}: {averageWaitingTime.toFixed(2)} {language === 'en' ? 'seconds' : 'ثواني'}</h2>
        </div>
      </header>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
